import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import TermsGrid from '../../components/terms/terms-grid'
import PolicyDetails from '../../components/terms/acceptable-use/acceptable-use'

const AcceptableUse = () => {
  return (
    <Layout>
      <SEO title='Acceptable Use' pageType='home' flowType='none' />
      {typeof window !== 'undefined' && (
        <TermsGrid pathname={window.location.pathname} />
      )}
      <PolicyDetails />
    </Layout>
  )
}

export default AcceptableUse
