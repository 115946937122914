import React from 'react'
import Policy from './acceptable-use.styles'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

const PolicyDetails = () => {
  return (
    <Policy>
      <Policy.title>Sitelio Acceptable Use</Policy.title>
      <Policy.paragraph>
        This Acceptable Use Policy ("AUP") governs your use of the Services and
        is incorporated by reference into Sitelio’s Terms of Services. Unless
        otherwise stated, defined terms in this AUP have the meanings provided
        to them in the Terms of Services. Sitelio may modify the AUP at any time
        without notice.
      </Policy.paragraph>
      <Policy.paragraph>
        You shall use the Services only for lawful purposes. Transmission,
        storage, or display of any information, data, or material in violation
        of applicable laws or regulations, including without limitation the laws
        of the Commonwealth of Massachusetts, is prohibited. Sitelio reserves
        the right to terminate the Services for any Customer that exposes
        Sitelio to legal liability or threatens its ability to provide services
        to other customers. You agree to indemnify and hold Sitelio harmless
        from any claims resulting from your use of the Services.
      </Policy.paragraph>
      <Policy.subtitle>QUICK LINKS</Policy.subtitle>
      <Policy.quickLinkContainer>
        <Policy.quickLink to='./#PROHIBITED-USES'>
          Prohibited Uses
        </Policy.quickLink>
        <Policy.paragraph>
          You may not use the Services to publish content or engage in activity
          that is harmful to others or illegal under applicable law. Any content
          that, in our judgment, violates our Terms of Service, including this
          AUP, in any manner may be removed from our servers (or otherwise
          disabled), with or without notice. Examples of prohibited content and
          activities can be found in this section.
        </Policy.paragraph>
      </Policy.quickLinkContainer>
      <Policy.quickLinkContainer>
        <Policy.quickLink to='./#ZERO-TOLERANCE'>
          Zero Tolerance Spam Policy
        </Policy.quickLink>
        <Policy.paragraph>
          Sitelio maintains a zero tolerance policy for use of its network or
          services to send unsolicited bulk or commercial e-mail, or the
          sending, assisting, or commissioning the transmission of commercial
          e-mail that does not comply with the U.S. CAN-SPAM Act of 2003
          ("SPAM"). Please review this section to avoid action under our AUP.
        </Policy.paragraph>
      </Policy.quickLinkContainer>
      <Policy.quickLinkContainer>
        <Policy.quickLink to='./#DEFAMATION'>
          Defamation and Objectionable Content
        </Policy.quickLink>
        <Policy.paragraph>
          Sitelio respects that the Internet provides a forum for free and open
          discussion and dissemination of information. Accordingly, we generally
          do not screen, monitor or control customer content. However, if we are
          made aware of content that violates the law or this AUP, we will
          consider the matter and take appropriate action as determined in our
          sole discretion balancing the merits of the complaint with the right
          to freedom of expression.
        </Policy.paragraph>
      </Policy.quickLinkContainer>
      <Policy.quickLinkContainer>
        <Policy.quickLink to='./#ENFORCEMENT'>Enforcement</Policy.quickLink>
        <Policy.paragraph>
          Sitelio may terminate your Services with or without notice upon any
          violation of this AUP. This section describes how Sitelio may enforce
          this AUP.
        </Policy.paragraph>
      </Policy.quickLinkContainer>
      <Policy.quickLinkContainer>
        <Policy.quickLink to='./#REPORTING'>
          Reporting AUP Violations
        </Policy.quickLink>
        <Policy.paragraph>
          If you feel you have discovered a violation of our AUP, please review
          this section to learn about our reporting procedures.
        </Policy.paragraph>
      </Policy.quickLinkContainer>
      <Policy.titleUpperBorder>Sitelio Acceptable Use</Policy.titleUpperBorder>
      <Policy.listSubtitle id={'PROHIBITED-USES'}>
        1. Prohibited Uses
      </Policy.listSubtitle>
      <Policy.list>
        <Policy.listChild>
          No Illegal or Harmful Use - You may not use the Services to publish
          content or engage in activity that is harmful to others or illegal
          under applicable law, including without limitation in connection with
          any of the following illegal, harmful or fraudulent activities:
        </Policy.listChild>
        <Policy.list isRomanNumerals>
          <Policy.listChild>
            Disclosing private sensitive personal information about others;
          </Policy.listChild>
          <Policy.listChild>
            Distributing malware or other malicious code;
          </Policy.listChild>
          <Policy.listChild>
            Engaging in the unlawful distribution of controlled substances and
            drug contraband;
          </Policy.listChild>
          <Policy.listChild>
            Engaging in the unlawful distribution of prescription medications,
            including, but not limited to, promotion, marketing, or sale of
            prescription medications without a valid prescription;
          </Policy.listChild>
          <Policy.listChild>Gambling;</Policy.listChild>
          <Policy.listChild>
            Hosting, distributing or linking to child sexual abuse material
            (CSAM) or content that is harmful to minors (CSAM will be suspended
            immediately without notice and reported to law enforcement or the
            National Center for Missing and Exploited Children);
          </Policy.listChild>
          <Policy.listChild>
            Hosting FOREX, E-Gold Exchange, Second Life/Linden Exchange, Ponzi,
            MLM/Pyramid Scheme, High-Yield Interest Programs (HYIP) or related
            sites;
          </Policy.listChild>
          <Policy.listChild>
            Hosting or linking to a website intended to deceive the public
            including, but not limited to sites listed at
            <Policy.inlineLink
              data-element-location={DataElementLocations.BODY}
              data-element-label='artists-419'
              data-element-id='sitelio-artists-419'
              to='http://wiki.aa419.org/index.php/Main_Page'
            >
              aa419.org{' '}
            </Policy.inlineLink>
            &
            <Policy.inlineLink
              data-element-location={DataElementLocations.BODY}
              data-element-label='escrow.fraud'
              data-element-id='sitelio-escrow-fraud'
              to='http://escrow-fraud.com/'
            >
              escrow-fraud.com
            </Policy.inlineLink>
            ;
          </Policy.listChild>
          <Policy.listChild>
            Hosting or linking to an anonymous proxy server;
          </Policy.listChild>
          <Policy.listChild>
            Infringing upon the Intellectual Property Rights of Others.This
            includes, but is not limited to, the unauthorized copying or
            distribution of movies, music, books, photographs, software/warez,
            or any other copyrighted work. If you believe that your intellectual
            property rights are being infringed upon, please email us at
            <Policy.inlineLink
              data-element-location={DataElementLocations.BODY}
              data-element-label='email.fraud'
              data-element-id='sitelio-email-fraud-proxy-server'
              href='mailto:abuse@Sitelio.com'
            >
              abuse@Sitelio.com
            </Policy.inlineLink>
            ;
          </Policy.listChild>
          <Policy.listChild>Money laundering;</Policy.listChild>
          <Policy.listChild>
            Phishing or engaging in identity theft;
          </Policy.listChild>
          <Policy.listChild>Selling weapons or ammunition;</Policy.listChild>
        </Policy.list>
        <Policy.listChild>
          No Unauthorized System Access or Network Abuse - You may not use the
          Services to gain access into any network or system without permission.
          Prohibited activities include:
        </Policy.listChild>
        <Policy.list isRomanNumerals>
          <Policy.listChild>
            Accessing another network without permission, to probe or scan for
            vulnerabilities or breach security or authentication measures;
          </Policy.listChild>
          <Policy.listChild>
            Attacking other networks (i.e. Denial of Service (DoS) attacks);
          </Policy.listChild>
          <Policy.listChild>
            Intercepting or monitoring data without permission;
          </Policy.listChild>
          <Policy.listChild>Running a file sharing site;</Policy.listChild>
          <Policy.listChild>
            Running any software that interfaces with an IRC (Internet Relay
            Chat) network;
          </Policy.listChild>
          <Policy.listChild>
            Using any deep-link, page-scrape, robot, crawl, index, spider,
            offline reader, click spam, macro programs, internet agent, or other
            automatic device, program, algorithm or methodology which does the
            same things, to use, access, copy, index, acquire information,
            generate impressions or clicks, input information, store
            information, search, generate searches, or monitor any portion of
            the Sitelio’s website or servers for any unauthorized purpose;
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild>
          Don't Hamper System Performance - Consuming excessive amount of server
          resources is prohibited. This leads to server performance issues and
          may cause a disruption to our systems or other customers. Use of any
          scripts or processes that may adversely impact our systems is
          prohibited.
        </Policy.listChild>
        <Policy.listChild>
          No Adult Content - You may not use the Services to distribute
          pornography or other adult-related content or offer any escort
          services.
        </Policy.listChild>
        <Policy.listChild>
          No Storage of Backups - Backing up personal data to a hosting account
          is prohibited. Our Services are designed to host your web site, not
          serve as a data repository. Sitelio reserves the right to remove
          backups from your hosting account.
        </Policy.listChild>
      </Policy.list>
      <Policy.listSubtitle id={'ZERO-TOLERANCE'}>
        2. Zero Tolerance Spam Policy
      </Policy.listSubtitle>
      <Policy.list>
        <Policy.listChild>
          You may not use the Services to send spam or bulk unsolicited
          messages. Sitelio maintains a zero tolerance policy for use of its
          network or services in any manner associated with the transmission,
          distribution or delivery of any bulk e-mail, including unsolicited
          bulk or unsolicited commercial e-mail, or the sending, assisting, or
          commissioning the transmission of commercial e-mail that does not
          comply with the U.S. CAN-SPAM Act of 2003 ("SPAM").
        </Policy.listChild>
        <Policy.listChild>
          "Safe lists," purchased lists, and selling of lists will be treated as
          SPAM. We may terminate the account of any User who sends out SPAM with
          or without notice.
        </Policy.listChild>
        <Policy.listChild>
          Websites advertised via SPAM (i.e. Spamvertised) may not be hosted on
          our servers. This provision includes, but is not limited to, SPAM sent
          via fax, phone, postal mail, email, instant messaging, or
          usenet/newsgroups. No organization or entity listed in the
          <Policy.inlineLink
            data-element-location={DataElementLocations.BODY}
            data-element-label='ROKSO-list'
            data-element-id='sitelio-ROKSO-list'
            to='https://www.spamhaus.org/rokso/?__cf_chl_jschl_tk__=14ecd3357baa484a9e780726468e30aa195c1b07-1608584463-0-AU7erz3DM4wCT8KjfssXpM6XXeZD4_aod--9_u6iGAlMpDL0Kjo6nq_IA9owV3oww1fOU3U4aTrDMv2tibHyt1sGvmY-kO0SRTLFcZdv5ulrrsZeS5nPODjzgNoxZrGjD4hnhva3OcfEAYrAHjDXMRHG5t8QsyWXA-iwXh4hkxo360CZx4rT222xT0g8zPszCqDVI1I6x53I_l6RAYPgRzFkDYHvIPdTp0FQNR6E4zmjAT3kOSCRqROFi5yQoeO-MW_uRLDnb7XMVZ7uAfqeOXRnMef00rsJNBf_0Wgy1r23UYhKYl7GyMrdHRsImtRojw'
          >
            ROKSO{' '}
          </Policy.inlineLink>
          may use our Services. Any User account which results in our IP space
          being blacklisted will be immediately suspended and/or terminated.
        </Policy.listChild>
        <Policy.listChild>
          Please familiarize yourself with the CAN-SPAM Act. Information about
          the CAN-SPAM Act may be found by accessing the following link:
          <Policy.inlineLink
            data-element-location={DataElementLocations.BODY}
            data-element-label='ftc-guidance'
            data-element-id='sitelio-tfc-guidance'
            to='https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business'
          >
            https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business
          </Policy.inlineLink>
        </Policy.listChild>
      </Policy.list>
      <Policy.listSubtitle id={'DEFAMATION'}>
        3. Defamation and Objectionable Content
      </Policy.listSubtitle>
      <Policy.paragraph>
        Sitelio values the freedom of expression and encourages Users to be
        respectful with the content they post. As a webhost, Sitelio is not a
        publisher of User content and is generally not in a position to
        investigate the veracity of individual defamation claims or to determine
        whether certain material, which Sitelio may find objectionable, should
        be censored. Accordingly, Sitelio generally requires a court order from
        a court of competent jurisdiction, as determined by Sitelio in its sole
        discretion, to take down alleged defamatory or objectionable content.
        However, Sitelio reserves the right to disable or remove any content to
        prevent harm to others or to Sitelio, as determined in Sitelio's sole
        discretion.
      </Policy.paragraph>
      <Policy.listSubtitle id={'ENFORCEMENT'}>
        4. Enforcement
      </Policy.listSubtitle>
      <Policy.list>
        <Policy.listChild>Resellers.</Policy.listChild>
        <Policy.list isRomanNumerals>
          <Policy.listChild>
            If there is a violation of this AUP by a User of a Reseller, Sitelio
            will suspend the account in question and notify the Reseller so that
            the Reseller can address the matter with the User. The occurrence of
            additional violations on a User account may result in the immediate
            suspensions or termination of your Reseller account.
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild>Direct Customers.</Policy.listChild>
        <Policy.list isRomanNumerals>
          <Policy.listChild>
            Your Services may be terminated with or without notice upon any
            violation of this AUP.
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild>
          If applicable, violations will be reported to the appropriate law
          enforcement agency.
        </Policy.listChild>
        <Policy.listChild>
          A failure to respond to an email from our compliance team within
          forty-eight (48) hours, or as otherwise specified in the email, may
          result in the suspension or termination of your Services.
        </Policy.listChild>
        <Policy.listChild>
          We reserve the right to enforce, or not enforce, this AUP in our sole
          discretion.
        </Policy.listChild>
      </Policy.list>
      <Policy.listSubtitle id={'REPORTING'}>
        5. Reporting Violations of this AUP
      </Policy.listSubtitle>
      <Policy.paragraph>
        If you feel you have discovered a violation of our AUP please email us
        at
        <Policy.inlineLink
          data-element-location={DataElementLocations.BODY}
          data-element-label='email.fraud'
          data-element-id='sitelio-email-fraud-AUP'
          href='mailto:abuse@Sitelio.com'
        >
          abuse@Sitelio.com
        </Policy.inlineLink>
        . We strive to make the Web a better place and consider the abuse of our
        services a serious matter. We investigate and record all reports of
        abuse submitted to us. Where appropriate, we may take action if a
        violation of any of our terms of service is confirmed or when required
        to do so if an activity violates the policies of ICANN or appropriate
        registry. We may request additional information from you about your
        report. However, please note we may not be able to provide you with
        specific updates in response to your specific report due to privacy
        reasons.
      </Policy.paragraph>
      <Policy.updatedText>Last updated: August 27, 2020</Policy.updatedText>
    </Policy>
  )
}

export default PolicyDetails
