import React from 'react'
import { TERMS_LIST } from '../terms.constants'
import GridLayout from './terms-grid.styles'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

const TermsGrid = ({ pathname }) => {
  return (
    <GridLayout>
      <GridLayout.container>
        <GridLayout.listItem>
          <GridLayout.title
            pathname={pathname}
            data-element-location={DataElementLocations.BODY}
            data-element-label='terms.link.grid'
            data-element-id={`sitelio-terms-link-grid-terms-index`}
            to={`/terms`}
          >
            Terms Index
          </GridLayout.title>
        </GridLayout.listItem>
        {TERMS_LIST.map((term) => (
          <GridLayout.listItem key={term.id}>
            <GridLayout.title
              pathname={pathname}
              data-element-location={DataElementLocations.BODY}
              data-element-label='terms.link.grid'
              data-element-id={`sitelio-terms-link-grid-${term.id}`}
              target={term.id === 'privacy-notice' ? '_blank' : '_self'}
              to={
                term.id === 'privacy-notice'
                  ? ' https://newfold.com/privacy-center '
                  : `/terms/${term.id}`
              }
            >
              {term.title}
            </GridLayout.title>
          </GridLayout.listItem>
        ))}
        <GridLayout.listItem />
      </GridLayout.container>
    </GridLayout>
  )
}

export default TermsGrid
