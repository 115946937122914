import styled from 'styled-components'
import theme, { media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const GridLayout = styled.div`
  padding: ${theme.space[10]}px 0;
`

GridLayout.container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  margin: 0 auto;
  justify-content: space-around;

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }

  ${media(1200)} {
    width: 1170px;
  }
`

GridLayout.listItem = styled.div`
  padding: ${theme.space[4]}px;
  text-transform: uppercase;
  font-size: ${theme.font['sm']};
  width: 50%;
  flex-basis: 40%;

  ${media('md')} {
    min-width: 120px;
    flex-basis: 25%;
  }
`

GridLayout.title = styled(Link)`
  text-decoration: none;
  color: ${(props) =>
    props.pathname === props.to ? theme.colors.black : theme.colors.blue};
  transition: 0.7s;

  :hover {
    color: ${theme.colors.font.tealhighlight};
    cursor: pointer;
  }
`

export default GridLayout
